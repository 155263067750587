






















































































































































































































































import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfPrice,
  SfAlert,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  useContext,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';

import {
  CartItemInterface,
  useBem,
  useCart,
  useGTM,
  useUiState,
} from '~/composables';

import SvgImage from '~/components/General/SvgImage.vue';

import { useCartView } from '~/modules/checkout/composables/useCartView';

import EmptyCart from '~/modules/checkout/components/cart/EmptyCart.vue';
import RsgCartProduct from '~/modules/checkout/components/cart-product';
import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfAlert,
    SvgImage,
    EmptyCart,
    RsgCartProduct,
  },
  setup() {
    const { trackEvent } = useGTM();
    const b = useBem();
    const cartView = useCartView();
    const { cart } = useCart();
    const { isCartSidebarOpen, closeCartSidebar } = useUiState();
    const { app } = useContext();
    const positionNote = ref<number>(null);
    const shippingPrice = computed(() => cartGetters.getShippingPrice(cart.value));

    const minOrderAmountError = computed(() => cart.value?.errors?.[0]?.message);
    const isErrorWithSomeProduct = computed(() => cart.value?.items?.some((product) => product?.errors));

    const { isDesktop } = app.$device;

    const handleUpdateItemQuantity = ({ product, quantity }: { product: CartItemInterface, quantity: number }) => {
      const currentItem = cart.value.items.find((item) => item.uid === product.uid);
      const currentQuantity = currentItem.quantity;
      if (quantity === 1 && currentQuantity === 1) {
        return;
      }

      const validQty = currentItem?.errors?.[0]?.message ? currentItem.salable_qty : quantity;
      cartView.delayedUpdateItemQty({ product, quantity: validQty });
    };

    onMounted(() => {
      trackEvent('view_cart');
    });

    return {
      ...cartView,
      cart,
      b,
      isCartSidebarOpen,
      isDesktop,
      closeCartSidebar,
      shippingPrice,
      positionNote,
      handleUpdateItemQuantity,
      minOrderAmountError,
      isErrorWithSomeProduct,
    };
  },
  methods: {
    positionNotification(position: DOMRect) {
      this.positionNote = position.y + 30;
    },
    scrollSidebar() {
      this.isRemoveModalVisible = false;
    },
  },

});
