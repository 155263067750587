import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  SfBadge, SfButton, SfImage, SfLink, SfPrice, SfQuantitySelector,
} from '@storefront-ui/vue';

import {
  ConfigurableCartItem, Product, useBem, useCartView, useImage, useProduct,
} from '~/composables';

import SvgImage from '~/components/General/SvgImage.vue';

import cartGetters from '~/modules/checkout/getters/cartGetters';

export const VIEW = {
  CHECKOUT: 'checkout',
  CART: 'cart',
};

export default defineComponent({
  name: 'RsgCartProduct',

  components: {
    SfBadge,
    SfButton,
    SfImage,
    SfLink,
    SfPrice,
    SfQuantitySelector,
    SvgImage,
  },

  props: {
    view: {
      type: String,
      default: VIEW.CHECKOUT,
      validator: (value: string) => Object.values(VIEW).includes(value),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<ConfigurableCartItem>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const b = useBem();
    const { isInStock: getIsInStock } = useCartView();

    const { getMagentoImage, imageSizes } = useImage();
    const { getProductPath } = useProduct();

    const image = computed(() => getMagentoImage(cartGetters.getItemImage(props.item)));
    const imageSize = computed(() => imageSizes[props.view]);
    const name = computed(() => cartGetters.getItemName(props.item));
    const link = computed(() => getProductPath(props.item.product as Product));
    const configurableOptions = computed(
      () => cartGetters.getItemConfigurableOptions(props.item)
        .map((option) => option.value_label)
        .join('/'),
    );
    const price = computed(() => cartGetters.getItemPrice(props.item));
    const hasSpecialPrice = computed(() => cartGetters.productHasSpecialPrice(props.item));
    const isInStock = computed(() => getIsInStock(props.item));

    const serverError = computed(() => props.item?.errors?.[0]?.message);
    const anyError = computed(() => !isInStock.value || !!serverError.value);

    const onQuantitySelectorInput = (quantity: number) => {
      emit('update-quantity', quantity);
    };

    const onRemoveClick = (e) => {
      const posY = (e.target as HTMLElement).getBoundingClientRect();
      emit('remove', posY);
    };

    return {
      b,

      image,
      imageSize,
      name,
      link,
      configurableOptions,
      price,
      hasSpecialPrice,
      isInStock,
      anyError,
      serverError,

      onQuantitySelectorInput,
      onRemoveClick,
    };
  },
});
