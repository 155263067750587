



























import { useBem } from '~/composables/useBem';
import {
  SfHeading,
  SfButton,
  SfLink,
} from '@storefront-ui/vue';
import {
  defineComponent, useRouter, useContext
} from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import {useUiState} from "~/composables";

export default defineComponent({
  name: 'EmptyCart',
  components: {
    SfHeading,
    SvgImage,
    SfButton,
    SfLink,
  },
  setup() {
    const b = useBem();
    const { toggleCartSidebar } = useUiState();
    const router = useRouter();


    return {
      b,
      toggleCartSidebar,
      router,
    };
  },
  methods: {
    toggleCartSidebarLink() {
      this.router.push(this.localePath('/full-products-category'));
      this.toggleCartSidebar();
      
    }
  }
});
